import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { store } from '../shared/store/slices/store';
import "./scss/galary.scss"
import ProductCard from "../shared/componets/ProdactCard"
import { Link, useLocation } from 'react-router-dom'
import {cardsSlice, getCards} from "../shared/store/slices/cards"
import LoadingPage from './LoadingPage'

export default function GallaryPage() {
  const location = useLocation()
  const cards = useSelector((state)=>state.cardsSlice)
  function filter(role) {
    return cards.items.filter((item, index) => item.role == role && <ProductCard key={index} item={item}/> )
  }
  useEffect(() => {
      window.scrollTo(0, 0)
  }, [location]) 
  useEffect(()=>{
    store.dispatch(getCards({}))
  }, [])
  return (
    <main>   
        <div className="gallary">
          {cards.isLoading ? <LoadingPage number={10}/> : 
          <>
          {document.documentElement.clientWidth > 630 && <span><Link to="/">главная </Link>/<Link to="/gallary"> Виды товаров</Link></span>}
            {filter("Аренда бытовки раздевалки").length > 0 && <>
              <h1>Аренда бытовки раздевалки</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Аренда бытовки раздевалки" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
            {filter("Аренда бытовки для проживания").length > 0 && <>
              <h1>Аренда бытовки для проживания</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Аренда бытовки для проживания" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
           {filter("Аренда бытовки c душем").length > 0 && <>
            <h1>Аренда бытовки c душем</h1>
            <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Аренда бытовки c душем" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
           {filter("Аренда бытовки прорабские").length > 0 && <>
           <h1>Аренда бытовки прорабские</h1>
            <div className="gallary__item">
            {cards.items.map((item, index) => item.role == "Аренда бытовки прорабские" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
          
            {filter("Аренда бытовки под склад").length > 0 && <>
              <h1>Аренда бытовки под склад</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Аренда бытовки под склад" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
            {filter("Аренда мебель").length > 0 && <>
              <h1>Аренда мебель</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Аренда мебель" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
            {filter("Производство модульное общежитие").length > 0 && <>
              <h1>Производство модульное общежитие</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство модульное общежитие" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
           {filter("Производство модульная столовая").length > 0 && <>
            <h1>Производство модульная столовая</h1>
            <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство модульная столовая" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
           {filter("Производство модульный храм").length > 0 && <>
           <h1>Производство модульный храм</h1>
            <div className="gallary__item">
            {cards.items.map((item, index) => item.role == "Производство модульный храм" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
          
            {filter("Производство штаб строительства").length > 0 && <>
              <h1>Производство штаб строительства</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство штаб строительства" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
               {filter("Производство модульные раздевалки").length > 0 && <>
              <h1>Производство модульные раздевалки</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство модульные раздевалки" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
            {filter("Производство модульные ФАПы").length > 0 && <>
              <h1>Производство модульные ФАПы</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство модульные ФАПы" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
           {filter("Производство модульные отели").length > 0 && <>
            <h1>Производство модульные отели</h1>
            <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство модульные отели" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
           {filter("Производство модульный офис").length > 0 && <>
           <h1>Производство модульный офис</h1>
            <div className="gallary__item">
            {cards.items.map((item, index) => item.role == "Производство модульный офис" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
          
            {filter("Производство вахтовый поселок").length > 0 && <>
              <h1>Производство вахтовый поселок</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство вахтовый поселок" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
            {filter("Производство строительный городок").length > 0 && <>
              <h1>Производство строительный городок</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство строительный городок" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
            {filter("Производство административно-бытовой комплекс").length > 0 && <>
              <h1>Производство административно-бытовой комплекс</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство административно-бытовой комплекс" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
           {filter("Производство блок контейнеры сантехнические").length > 0 && <>
            <h1>Производство блок контейнеры сантехнические</h1>
            <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство блок контейнеры сантехнические" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
           {filter("Производство Блок контейнеры строительные").length > 0 && <>
           <h1>Производство Блок контейнеры строительные</h1>
            <div className="gallary__item">
            {cards.items.map((item, index) => item.role == "Производство Блок контейнеры строительные" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
          
            {filter("Производство  контейнеры из сэндвич панелей").length > 0 && <>
              <h1>Производство  контейнеры из сэндвич панелей</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство  контейнеры из сэндвич панелей" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
            {filter("Производство Блок контейнер под склад").length > 0 && <>
           <h1>Производство Блок контейнер под склад</h1>
            <div className="gallary__item">
            {cards.items.map((item, index) => item.role == "Производство Блок контейнер под склад" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
          
            {filter("Производство Модульные посты охраны").length > 0 && <>
              <h1>Производство Модульные посты охраны</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство Модульные посты охраны" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
               {filter("Производство Проходная").length > 0 && <>
              <h1>Производство Проходная</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство Проходная" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
            {filter("Производство Будка охраны").length > 0 && <>
              <h1>Производство Будка охраны</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство Будка охраны" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
           {filter("Производство Бытовки строительные").length > 0 && <>
            <h1>Производство Бытовки строительные</h1>
            <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство Бытовки строительные" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
           {filter("Производство Бытовки металлические").length > 0 && <>
           <h1>Производство Бытовки металлические</h1>
            <div className="gallary__item">
            {cards.items.map((item, index) => item.role == "Производство Бытовки металлические" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
          
            {filter("Производство Бытовки с душем и туалетом").length > 0 && <>
              <h1>Производство Бытовки с душем и туалетом</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство Бытовки с душем и туалетом" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
            {filter("Производство Бытовки из сэндвич панелей").length > 0 && <>
              <h1>Производство Бытовки из сэндвич панелей</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство Бытовки из сэндвич панелей" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
            {filter("Производство Бытовки утепленные").length > 0 && <>
              <h1>Производство Бытовки утепленные</h1>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == "Производство Бытовки утепленные" && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
           {filter("Производство Хозблок модульный").length > 0 && <>
            <h1>Производство Хозблок модульный</h1>
            <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство Хозблок модульный" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
           {filter("Производство Модульный туалет").length > 0 && <>
           <h1>Производство Модульный туалет</h1>
            <div className="gallary__item">
            {cards.items.map((item, index) => item.role == "Производство Модульный туалет" && <ProductCard key={index} item={item}/> )}
            </div>
           </>}
          
            {filter("Производство Строительный вагончик").length > 0 && <>
              <h1>Производство  контейнеры из сэндвич панелей</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "Производство Строительный вагончик" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
            {filter("общая").length > 0 && <>
              <h1>Общий вид</h1>
              <div className="gallary__item">
              {cards.items.map((item, index) => item.role == "общая" && <ProductCard key={index} item={item}/> )}
              </div>
            </>
            }
              </>}
        </div>
    </main>
  )
}
