import React,  { useState, useEffect, useRef}  from 'react'
import { useSelector } from 'react-redux'
import {orderSliceClient, getAnswers} from "../shared/store/slices/orderClient"
import { store } from '../shared/store/slices/store';
import "./scss/chat.scss"
export default function Chat() {
  const MiddleAnswers = useSelector((state)=>state.orderSliceClient.answers)
  let answers = MiddleAnswers.map((item,idx) => MiddleAnswers[MiddleAnswers.length-1-idx])

  useEffect(()=>{
    store.dispatch(getAnswers({}))
  }, [])
  return (
    <div  className='chat'>
        <h1>Вопросы</h1>
        {answers.map(item=><div className="bag__block">
            <img className='card__img' src='../userIcon.svg' alt={`Вопрос № ${item.idAnswer}`}/>
            <div className="block__content"> 
                <p className="orderNumber">{item.name == 'доставка' ? 'Вопрос по доставки' : `Вопрос от ${item.name}`} </p>
                <p className='rent'>{item.com}</p>
            </div>
            <div className="block__info__user">
                  <div className="user__row">
                    <span>Email:</span>
                    <p> {item.email == '' ? 'Не указано' : item.email}</p>
                  </div>
                  <div className="user__row">
                  <span>Номер телефона:</span>
                    <p>{item.number == '' ? 'Не указано' : item.number}</p>
                  </div>
                  <div className="user__row">
                  <span>Имя:</span>
                    <p>{item.name == 'доставка' ? 'Не указано' : item.name}</p>
                  </div>
                </div>
      </div>)}
    </div>
  )
}
