import React from 'react'
import {Link} from 'react-router-dom'
import './scss/NotFound.scss'

export default function NotFoundPage() {
  return (
    <div className='empty'>
      <h1 className='empty__h1'>404</h1>
      <p>страница не найдена</p>
      <p>перейти на <Link to='/'>главную страницу</Link></p>
    </div>
  )
}
