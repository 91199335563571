import React, {useEffect, useState} from 'react'
import {cardsSlice, getCards, getCardsImg, addCards,addCardsBuild} from "../shared/store/slices/cards"
import { useSelector } from 'react-redux'
import { store } from '../shared/store/slices/store';
import { Link, useLocation} from 'react-router-dom';
import CardsCard from '../shared/componets/CardsCard';
import "./scss/activeCard.scss"
import LoadingPageAdmin from './LoadingPageAdmin'

export default function ActiveCard() {
  const location = useLocation()
  const cards = useSelector((state)=>state.cardsSlice)
  const [showSideBar, setShowSideBar] = useState(false)
  function reverse(array){
    return array.filter(item =>item?.role.split(' ')[0] == 'Производство').map((item,idx) => array[array.length-1-idx])
  }
  
  const [orderFilter, setOrderFilter] = useState(cards?.items)
  let newMassReverse = reverse(orderFilter)
  
  async function MiddleWareCreateCard() {
    await store.dispatch(addCardsBuild({}))
  }
  const [nameChecked ,setNameChecked] = useState({
    "Производство модульное общежитие": false,
    "Производство модульная столовая": false,
    "Производство модульный храм": false,
    "Производство штаб строительства": false,
    "Производство модульные раздевалки": false,
    "Производство модульные ФАПы": false,
    "Производство модульные отели": false,
    "Производство модульный офис": false,
    "Производство вахтовый поселок": false,
    "Производство строительный городок": false,
    "Производство административно-бытовой комплекс": false,
    "Производство блок контейнеры сантехнические": false,
    "Производство блок контейнеры строительные": false,
    "Производство блок контейнеры сэндвич-панели": false,
    "Производство блок контейнер склад": false,
    "Производство Модульные посты охраны": false,
    "Производство Проходная": false,
    "Производство Будка охраны": false,
    "Производство Бытовки строительные": false,
    "Производство Бытовки металлические": false,
    "Производство Бытовки с душем и туалетом": false,
    "Производство Бытовки из сэндвич панелей": false,
    "Производство Бытовки утепленные": false,
    "Производство Хозблок модульный": false,
    "Производство Модульный туалет": false,
    "Производство Строительный вагончик": false,
    "Производство Модульный вагончик": false,
  })
  let newMass = []
  for (let key in nameChecked){
    newMass = [...newMass, key]
  }

  function allCheckboxFalse(object) {
    let flag = true;
    Object.values(object).forEach(x=>{
        if (x) {flag = false}})
    return flag
  }

  function filterData() {
    const filterMass = cards?.items.filter(item => {
        let trueItem = true;
        if (!allCheckboxFalse(nameChecked)) {
            trueItem = trueItem && (nameChecked[item.role] ? true : false)
            return trueItem
          } else{
          return trueItem
        }
    })
    console.log(filterMass);
    setOrderFilter(filterMass)
  }
  function ShowFillter() {
    return (
    <div className="katalog__filter2">
        <div className="katalog-name">
          <div className="katalog-row">
            <h2>Наименование</h2>
            <p className="filter__close" onClick={()=>setShowSideBar(!showSideBar)} >x</p>
          </div>
         <>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство модульное общежитие": !nameChecked["Производство модульное общежитие"]})} checked={nameChecked["Производство модульное общежитие"]}/>{"модульное общежитие"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство модульная столовая": !nameChecked["Производство модульная столовая"]})} checked={nameChecked["Производство модульная столовая"]}/>{"модульная столовая"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство модульный храм": !nameChecked["Производство модульный храм"]})} checked={nameChecked["Производство модульный храм"]}/>{"модульный храм"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  'Производство штаб строительства': !nameChecked['Производство штаб строительства']})} checked={nameChecked['Производство штаб строительства']}/>{'штаб строительства'}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство модульные раздевалки": !nameChecked["Производство модульные раздевалки"]})} checked={nameChecked["Производство модульные раздевалки"]}/>{"модульные раздевалки"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство модульные ФАПы": !nameChecked["Производство модульные ФАПы"]})} checked={nameChecked["Производство модульные ФАПы"]}/>{"модульные ФАПы"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство модульные отели": !nameChecked["Производство модульные отели"]})} checked={nameChecked["Производство модульные отели"]}/>{"модульные отели"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство блок контейнеры сантехнические": !nameChecked["Производство блок контейнеры сантехнические"]})} checked={nameChecked["Производство блок контейнеры сантехнические"]}/>{"блок контейнеры сантехнические"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  'Производство Блок контейнеры строительные': !nameChecked['Производство Блок контейнеры строительные']})} checked={nameChecked['Производство Блок контейнеры строительные']}/>{'Произконтейнеры строительные'}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство  контейнеры из сэндвич панелей": !nameChecked["Производство  контейнеры из сэндвич панелей"]})} checked={nameChecked["Производство  контейнеры из сэндвич панелей"]}/>{"блок контейнеры из сэндвич панелей"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Блок контейнер под склад": !nameChecked["Производство Блок контейнер под склад"]})} checked={nameChecked["Производство Блок контейнер под склад"]}/>{"Блок контейнер под склад"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  'Производство Модульные посты охраны': !nameChecked['Производство Модульные посты охраны']})} checked={nameChecked['Производство Модульные посты охраны']}/>{'Модульные посты охраны'}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Проходная": !nameChecked["Производство Проходная"]})} checked={nameChecked["Производство Проходная"]}/>{"Проходная"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Будка охраны": !nameChecked["Производство Будка охраны"]})} checked={nameChecked["Производство Будка охраны"]}/>{"Будка охраны"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Бытовки строительные": !nameChecked["Производство Бытовки строительные"]})} checked={nameChecked["Производство Бытовки строительные"]}/>{"Бытовки строительные"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  'Производство Бытовки металлические': !nameChecked['Производство Бытовки металлические']})} checked={nameChecked['Производство Бытовки металлические']}/>{'Бытовки металлические'}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Бытовки с душем и туалетом": !nameChecked["Производство Бытовки с душем и туалетом"]})} checked={nameChecked["Производство Бытовки с душем и туалетом"]}/>{"Бытовки с душем и туалетом"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Бытовки из сэндвич панелей": !nameChecked["Производство Бытовки из сэндвич панелей"]})} checked={nameChecked["Производство Бытовки из сэндвич панелей"]}/>{"Бытовки из сэндвич панелей"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Бытовки утепленные": !nameChecked["Производство Бытовки утепленные"]})} checked={nameChecked["Производство Бытовки утепленные"]}/>{"Бытовки утепленные"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Хозблок модульный": !nameChecked["Производство Хозблок модульный"]})} checked={nameChecked["Производство Хозблок модульный"]}/>{"Хозблок модульный"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Модульный туалет": !nameChecked["Производство Модульный туалет"]})} checked={nameChecked["Производство Модульный туалет"]}/>{"Модульный туалет"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Строительный вагончик": !nameChecked["Производство Строительный вагончик"]})} checked={nameChecked["Производство Строительный вагончик"]}/>{"Строительный вагончик"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Производство Модульный вагончик": !nameChecked["Производство Модульный вагончик"]})} checked={nameChecked["Производство Модульный вагончик"]}/>{"Модульный вагончик"}</label>

         </> 
        </div>
    </div>
    )
 }

  useEffect(()=>{
    store.dispatch(getCards({}))
    store.dispatch(getCardsImg({}))
  }, [])
  useEffect(()=>{
    filterData()
  }, [cards?.items, nameChecked])
  useEffect(() => {
    window.scrollTo(0, 0)
}, [location])
  return (
    <div className='activeCard'>
      <div className='headerOrder'>
        <button className='btnForCreate' onClick={()=>MiddleWareCreateCard()} >Создать карточку</button> 
        <div className="filter__open" onClick={()=>setShowSideBar(!showSideBar)}><img src="/filter.webp" alt="фильтры"/><p>фильтры</p></div>
      </div>
      <h1>Карточки производства</h1>
      <div className="cards">
        {cards.isLoading ? <LoadingPageAdmin number={3}/> : cards?.items.length != 0 ?  newMassReverse.map((item, index)=><CardsCard key={index} item={item}/>) :  <div className="order__clear">
            <h2>Карточек пока нет</h2>
            <p><Link>создайте карточку</Link> чтобы она появилась здесь</p>
        </div>}
      </div>
      {showSideBar && ShowFillter()}
      {showSideBar && <div className="background__black"></div>} 
    </div>
  )
}
