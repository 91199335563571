import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { store } from '../shared/store/slices/store';
import "./scss/galary.scss"
import ProductCard from "../shared/componets/ProdactCard"
import { Link, useLocation } from 'react-router-dom'
import {answer, authSlice} from "../shared/store/slices/auth"
import {cardsSlice, getCards} from "../shared/store/slices/cards"
import LoadingPage from './LoadingPage'

export default function BuildModulCubinsHostelPage() {
  const location = useLocation()
  const auth = useSelector((state)=>state.authSlice)
  const cards = useSelector((state)=>state.cardsSlice)
  const nameCard = localStorage.getItem('nameMainCard')?.split(`"`)[1]
  useEffect(() => {
      setCreatorToggle({...newObj, standart:true})
      window.scrollTo(0, 0)
  }, [location]) 
  function filter(role) {
    
    return cards.items.filter((item, index) => item.role == role && <ProductCard key={index} item={item}/> )
  }

  const [form,setForm] = useState({
    name: '',
    number: '',
    email: '',
    com: ''
  })
  function changeInput(key, e) {
    setForm({...form, [key]: e.target.value})
  }

  const [checkInput, setCheckInput] = useState('')
  const [checkInputTranspor, setCheckInputTranspor] = useState('')
  const [creatorToggle, setCreatorToggle] = useState({
    standart: true,
    custom: false,
    gallary:false,
    specification: false,
    complect: false,
    transpor: false,
  })
  const newObj = {
    standart: false,
    custom: false,
    gallary:false,
    specification: false,
    complect: false,
    transpor: false,
  }
  const ObjectImg ={
    "Производство модульное общежитие": './modulHouse.png',
    "Производство модульная столовая": './kitchenHouse.jpeg',
    "Производство модульный храм": './ChurchHouse.jpeg',
    "Производство штаб строительства": './ShtabHouse.jpeg',
    "Производство модульные раздевалки": './modulChange.jpeg',
    "Производство модульные ФАПы": './modulFAPjpeg.jpeg',
    "Производство модульные отели": './HotelHouse.jpeg',
    "Производство модульный офис": './OffiseHouse.jpeg',
    "Производство вахтовый поселок": './villageHouse.jpeg',
    "Производство строительный городок": './CItyHouse.jpeg',
    "Производство административно-бытовой комплекс": './adminHouse.jpeg',
    "Производство блок контейнеры сантехнические": './BlockSholders.jpeg',
    "Производство блок контейнеры строительные": './buildHouseBlock.jpg',
    "Производство блок контейнеры сэндвич-панели": './burderBlock.jpeg',
    "Производство блок контейнер склад": './wareHouseBlock.jpeg',
    "Производство Модульные посты охраны": './policeBlock.jpeg',
    "Производство Проходная": './exiteBlock.jpeg',
    "Производство Будка охраны": './policeDogHouse.jpeg',
    "Производство Бытовки строительные": './ModulHouseBuildBlock.jpeg',
    "Производство Бытовки металлические": './meModulHouse.jpeg',
    "Производство Бытовки с душем и туалетом": './mudulHouseTualet.jpeg',
    "Производство Бытовки из сэндвич панелей": './ModulHouseBurger.jpeg',
    "Производство Бытовки утепленные": './ModulHouseForSnow.jpeg',
    "Производство Хозблок модульный": './laundryHouse.jpg',
    "Производство Модульный туалет": './ModuHouseTualet2.jpeg',
    "Производство Строительный вагончик": './trinBuild.jpeg',
    "Производство Модульный вагончик": './trainHouse.jpeg',
  }
  function renderCharacter(name) {
    let indexObject = 0
    Object.keys(ObjectImg).map((k,index)=>{
      if(k == nameCard){
      indexObject = index
    }})
    if (indexObject <= 10 || (indexObject >= 15 && indexObject <= 17)){
      if (name == 'Габаритные размеры'){ return '6,0 м х 2,45 м х 2,45 м' }
      if (name == 'Каркас'){ return 'Швеллер 125 мм, уголок 140 мм, цвет синий' }
      if (name == 'Внешняя отделка'){ return 'Сэндвич-панели (100 мм Rockwool), цвет по таблице RAL' }
      if (name == 'Внутренняя отделка'){ return 'Сэндвич-панели (100 мм Rockwool), потолок – ПВХ панели (цвет белый)'}
      if (name == 'Утепление'){ return '(100 мм Rockwool)'}
      if (name == 'Дверь'){ return 'Металлическая дверь снаружи, внутри дермантин' }
      if (name == 'Окно'){ return 'ПВХ (0,8х1мм) поворотно-откидное' }
      if (name == 'Пол'){ return 'ОСБ 18 мм' }
      if (name == 'Крыша'){ return 'Плоская, из листовой стали 0,7 мм' }
      if (name == 'Пароизоляция'){ return 'Гидропроф Б' }
      if (name == 'Внутренняя обрешетка'){ return 'Обрешётка (пол, потолок) брус 1 сорта 100х40 мм., утепление УРСА терра 100мм' }
      if (name == 'Дно'){ return 'Металлический лист 1,0 мм'}
      if (name == 'Электрика'){ return 'ЕВРО (2 светодиодных светильника, 1 выключатель, 2 розетки, автомат 25А)'}
    }
    if ((indexObject >= 11 && indexObject <= 14)|| (indexObject >= 18 && indexObject <= 22) || (indexObject >= 23 && indexObject <= 24) || (indexObject >= 25 && indexObject <= 26)){
      if (name == 'Габаритные размеры'){ return '5,85 м х 2,4 м х 2,4 м' }
      if (name == 'Каркас'){ return 'Швеллер 100 мм, уголок 70 х 70 мм х/г, цвет синий' }
      if (name == 'Внешняя отделка'){ return 'Оцинкованный профлист С8' }
      if (name == 'Внутренняя отделка'){ return 'ДВП'}
      if (name == 'Утепление'){ return '50 мм, утеплитель любой'}
      if (name == 'Дверь'){ return 'ДВП, обшита гладким оцинкованным листом с врезным замком, ручки «грибок»' }
      if (name == 'Окно'){ return '(0,75 х 0,9) деревянное (двойное остекление)' }
      if (name == 'Пол'){ return 'OSB 12 мм (утепление 100 мм)' }
      if (name == 'Крыша'){ return 'Металлический сварной лист 0,5 мм' }
      if (name == 'Пароизоляция'){ return 'Пленка 40 мкрн' }
      if (name == 'Внутренняя обрешетка'){ return 'Брус 2 сорта 50 х 40 мм, срощенные, осина. Допустимо наличие обзола.' }
      if (name == 'Дно'){ return 'Без металлического дна'}
      if (name == 'Электрика'){ return 'Отсутствует'}
    }
  }
 
  function send() {
    if ( form.email == "" || form.com == "" || form.name == "" || form.phone == "") {
      setCheckInput("Поля не заполненны")
    } else {
          store.dispatch(answer({body: form})) 
    } 
  }
  function sendTranspor() {
    if (form.com == "" || form.number == "") {
      setCheckInputTranspor("Поля не заполненны")
    } else {
          store.dispatch(answer({body: {
            name:  `доставка`,
            number: form.number,
            email: '',
            com: form.com,
          }})) 
    } 
  }

  useEffect(()=>{
    store.dispatch(getCards({}))
  }, [])
  useEffect(()=>{
    if (auth.answer == 'отправлено' && !auth.isLoading  ) {
      setCheckInput("Сообщение: вопрос был отправлен")
    }
    if (auth.answer == 'доставка' && !auth.isLoading  ) {
      setCheckInputTranspor("Сообщение: вопрос был отправлен")
    }
  }, [auth.answer])
  return (
    <main>   
        <div className="gallary">
          {cards.isLoading ? <LoadingPage number={10}/> : <>
          {document.documentElement.clientWidth > 630 && <span><Link to="/">главная </Link>/<Link to="/gallary"> {nameCard}</Link></span>}
          { nameCard?.split(' ')[0] == 'Производство' &&
                <div className="gallary__row">
                <div className="gallary__col">
                <h1 className='gallary__text'>{nameCard?.split(' ')[1].toLowerCase() == 'блок' ? `${nameCard?.split(' ')[1]} ${nameCard?.split(' ')[2]} ${nameCard.split(' ')[3]}` : `${nameCard.split(' ')[1]} ${nameCard.split(' ')[2]}`} </h1>
                  <div className="gallary__arrow">
                    <button onClick={()=>setCreatorToggle({...newObj, custom: true})}>Получить КП</button>
                    {document.documentElement.clientWidth > 1250 &&<div>
                      <p>Прикрепите свое ТЗ, и мы подготовим для <br /> вас расчет стоимости и схемы</p>
                      <img src="./arrowKP.svg" alt="стрелка" />
                    </div>}
                  </div>
                </div>
                {Object.keys(ObjectImg).map(k =>k == nameCard && <img className="gallary__main__img"  src={ObjectImg[k]} alt={nameCard} />)}
              </div>
          }
          {nameCard?.split(' ')[0] == 'Аренда' &&  <>   {document.documentElement.clientWidth > 630 && <h1>{nameCard}</h1>} 
            {filter(nameCard).length > 0 && <>
              <div className="gallary__item">
                {cards.items.map((item, index) => item.role == nameCard && <ProductCard key={index} item={item}/> )}
              </div>
            </>}
            {filter(nameCard).length == 0 && <div className="bag__clear">
              <h2>других товаров пока нет</h2>
              <p>вернитесь в <Link to="/katalog">каталог</Link> и посмотрите основные товары</p>
            </div>}
          </>}
          {nameCard?.split(' ')[0] != 'Аренда' && <>
            <div className="gallary__choose">
              <div className="choose__col">
                <button className={creatorToggle.standart &&`active`} onClick={()=>setCreatorToggle({...newObj, standart: true})}>Типовые</button>
                <button className={creatorToggle.custom &&`active`} onClick={()=>setCreatorToggle({...newObj, custom: true})}>Индивидуальные</button>
                <button className={creatorToggle.gallary &&`active`} onClick={()=>setCreatorToggle({...newObj, gallary: true})}>Фотогалерея</button>
              </div>
             <div className="choose__col">
                <button className={creatorToggle.specification &&`active`} onClick={()=>setCreatorToggle({...newObj, specification: true})}>Характеристики</button>
                <button className={creatorToggle.complect &&`active`} onClick={()=>setCreatorToggle({...newObj, complect: true})}>Комплектация</button>
                <button className={creatorToggle.transpor &&`active`} onClick={()=>setCreatorToggle({...newObj, transpor: true})}>Доставка и монтаж</button>
            </div>
            </div>
            { creatorToggle.standart && <>
               <h2>Типовые решения</h2>
               {filter(nameCard).length > 0 && <>
                 <div className="gallary__item">
                   {cards.items.map((item, index) => item.role == nameCard && <ProductCard key={index} item={item}/> )}
                 </div>
               </>}
               {filter(nameCard).length == 0 && <div className="bag__clear">
                 <h2>других товаров пока нет</h2>
                 <p>вернитесь на <Link to="/buildingCubins">главную</Link> и посмотрите основные товары</p>
               </div>}</>
            }
            {
              creatorToggle.custom && <>
              <div className="custom__row">
              {document.documentElement.clientWidth > 1000 &&
                <div className="custom__text">
                  <h2>Не нашли среди типовых предложений подходящее решение?</h2>
                  <p>На сайте представлены далеко не все типовые решения, которые мы производим и можем вам предложить. </p>
                  <p>Также мы создаем <span>индивидуальные проекты любой сложности.</span></p>
                  <p>Отправьте нам ваше техническое задание или опишите, какую задачу вам необходимо решить, и мы отправим вам КП.</p>
                </div>
              }
                <div className="custom__form">
                {document.documentElement.clientWidth < 630 &&   <h2>Получить КП</h2>}
                  <input type="text" placeholder='Ваше имя'  value={form.name} onChange={(e)=>changeInput("name", e)}/>
                  <div className="custom__fdRow">
                    <input type="phone" placeholder='Номер телефона'  value={form.number} onChange={(e)=>changeInput("number", e)}/>
                    <input type="email" placeholder='Эл.почта'  value={form.email} onChange={(e)=>changeInput("email", e)}/>
                  </div>
                  <input type="text" placeholder='Комментарий или вопрос'  value={form.com} onChange={(e)=>changeInput("com", e)}/>
                  <p className='error'>{checkInput}</p>
                  <button className={`answer ${!auth.isLoading  ? "" : "loading"} `} disabled={auth.isLoading } onClick={send}>{!auth.isLoading  ? "Отправить" : "Загрузка"}</button>
                </div>
              </div>
              </>
            }
            {creatorToggle.gallary && <>
              <h2>Фотогалерея проектов</h2>
              <div className="photo__container1">
                <img src="./photoContainer.webp" alt="контэйнер" />
                <img src="./photoMoreContainer.webp" alt="контэйнер" />
              </div>
              <div className="photo__container2">
                <img src="./orangeContainer.webp" alt="контэйнер" />
                <img src="./houseContainer.webp" alt="контэйнер" />
              </div>
            </>   
            }
            {
              creatorToggle.specification && <>
                <h2>Технические характеристики</h2>
                <div className="spec__row">
                  <div className="spec__left">
                    <div className="spec__text"><p>Габаритные размеры</p></div>
                    <div className="spec__text"><p>Каркас</p></div>
                    <div className="spec__text"><p>Внешняя отделка</p></div>
                    <div className="spec__text"><p>Внутренняя отделка</p></div>
                    <div className="spec__text"><p>Утепление</p></div>
                    <div className="spec__text"><p>Дверь</p></div>
                    <div className="spec__text"><p>Окно</p></div>
                    <div className="spec__text"><p>Пол</p></div>
                    <div className="spec__text"><p>Крыша</p></div>
                    <div className="spec__text"><p>Пароизоляция</p></div>
                    <div className="spec__text"><p>Внутренняя обрешетка</p></div>
                    <div className="spec__text"><p>Дно</p></div>
                    <div className="spec__text"><p>Электрика</p></div>
                  </div>
                  <div className="spec__right">
                    <div className="spec__text"><p>{renderCharacter('Габаритные размеры')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Каркас')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Внешняя отделка')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Внутренняя отделка')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Утепление')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Дверь')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Окно')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Пол')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Крыша')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Пароизоляция')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Внутренняя обрешетка')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Дно')}</p></div>
                    <div className="spec__text"><p>{renderCharacter('Электрика')}</p></div>
                  </div>
                </div>
              </>
            }
            {creatorToggle.complect && <>
              <h2>Дополнительная комплектация</h2>
              <div className="complect__row">
                <div className="complect__block">
                  <img src="./batary.svg" alt="батарея" />
                  <p>Системы отопления</p>
                </div>
                <div className="complect__block">
                <img src="./vent.svg" alt="вентилятор" />
                <p>Вентиляция</p>
                </div>
                <div className="complect__block">
                <img src="./dumbs.svg" alt="трубы" />
                <p>Автономное водоснабжение</p>
                </div>
                <div className="complect__block">
                <img src="./shower.svg" alt="душ" />
                <p>Сантехника</p>
                </div>
              </div>
              <div className="complect__row">
              <div className="complect__block">
                <img src="./fire.svg" alt="огнетушитель" />
                <p>Огнетушители</p>
              </div>
              <div className="complect__block">
                <img src="./twoBed.svg" alt="кровать" />
                <p>Мебель</p>
              </div>
              <div className="complect__block">
                <img src="./power.svg" alt="зарядка" />
                <p>Оборудование</p>
              </div>
              <div className="complect__block">
                <img src="./rulon.svg" alt="обои" />
                <p>Дополнительная отделка</p>
              </div>
              </div>
            </>
          }
          { creatorToggle.transpor && <>
            <h2>Доставка и монтаж</h2>
            <div className="transpor__row">
              <div className="transpor__col">
              <div className="transpor__container">
                <div className="transpor__photo">
                  <img src="./manipulator.png" alt="манипулятор" />
                  <h3>Доставка манипулятором</h3>
                  <p>Доставка по Москве до 30 км за МКАД: <span>14000₽</span></p>
                  <p>Цена за каждый дополнительный километр до 300 км:  <span>70р/км.</span></p>
                </div>
              </div>
              <div className="transpor__container">
              <div className="transpor__photo">
                <img src="./longler.png" alt="длиномер" />
                <h3>Доставка длинномером</h3>
                <p>Доставка по Москве до 30 км за МКАД: <span>18000₽</span></p>
                <p>Цена за каждый дополнительный километр до 300 км:  <span>70р/км.</span></p>
              </div>
              </div>
              </div>
            
       
              <div className="transpor__form">
                <h3>Доставка по России</h3>
                {document.documentElement.clientWidth > 1000 && <p>Осуществляем доставку по всей России. Стоимость доставки зависит от региона и объема груза и рассчитывается индивидуально.</p>}
                <input type="phone"  value={form.number} onChange={(e)=>changeInput("number", e)} placeholder='Номер телефона'/>
                <input type="text" value={form.com} onChange={(e)=>changeInput("com", e)} placeholder='Укажите регион и комментарий по заказу'/>
                <p className='error'>{checkInputTranspor}</p>
                <button className={`answer ${!auth.isLoading  ? "" : "loading"} `} disabled={auth.isLoading } onClick={sendTranspor}>{!auth.isLoading  ? "Отправить" : "Загрузка"}</button>
              </div>
            </div>
          </>
          }
          {document.documentElement.clientWidth > 1230 &&
          <div className="reduce__container">
            <div className="reduce__block">
              <img src="./reduce.svg" alt="рост" />
              <p>производственные мощности <br /> <span>до 500 единиц продукции/месяц</span></p>
            </div>
            <div className="reduce__block">
              <img src="./blank.svg" alt="рост" />
              <p>изготовление по ГОСТ <br /> <span>продукция сертифицирована</span></p>
            </div>
            <div className="reduce__block">
              <img src="./kamaz.svg" alt="рост" />
              <p>доставка по Москве, МО и по России <br /> <span>собственный автопарк</span></p>
            </div>
          </div>
          }
          </>}
          </>}
    
      
        </div>
    </main>
  )
}
