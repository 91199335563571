import React,  { useState, useEffect, useRef}  from 'react'
import { Link } from 'react-router-dom'
import {cardsSlice, getCards} from "../shared/store/slices/cards"
import { useSelector } from 'react-redux'
import { store } from '../shared/store/slices/store';
import "./scss/BuildingCubins.scss"
import LazyLoad from 'react-lazy-load';
import {BagSlice} from "../shared/store/slices/bag"
export default function BuildingCubinsPage() {
    const cards = useSelector((state)=>state.cardsSlice)
    const nameCard = localStorage.getItem('nameMainCard')?.split(`"`)[1]
    useEffect(()=>{
        store.dispatch(getCards({}))
    }, [])
    let ModulCabins = [
      {
        img:'./modulHouse.png',
        name: 'модульное общежитие',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./kitchenHouse.jpeg',
        name: 'модульная столовая',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./ChurchHouse.jpeg',
        name: 'модульный храм',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./ShtabHouse.jpeg',
        name: 'штаб строительства',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./modulChange.jpeg',
        name: 'модульные раздевалки',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./modulFAPjpeg.jpeg',
        name: 'модульные ФАПы',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./HotelHouse.jpeg',
        name: 'модульные отели',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./OffiseHouse.jpeg',
        name: 'модульный офис',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./villageHouse.jpeg',
        name: 'вахтовый поселок',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      },
      {
        img:'./adminHouse.jpeg',
        name: 'административно-бытовой комплекс',
        price: 1635000,
        discount: 1735000,
        star: '★★★★☆',
      }
    ]
    let ModulBlock = [
        {
          img:'./BlockSholders.jpeg',
          name: 'блок контейнеры сантехнические',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./buildHouseBlock.jpg',
          name: 'блок контейнеры строительные',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./burderBlock.jpeg',
          name: 'блок контейнеры сэндвич-панели',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./wareHouseBlock.jpeg',
          name: 'блок контейнер склад',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
      ]
      let ModulKPP = [
        {
          img:'./policeBlock.jpeg',
          name: 'Модульные посты охраны',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./exiteBlock.jpeg',
          name: 'Проходная',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./policeDogHouse.jpeg',
          name: 'Будка охраны',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
      ]
      let ModulHouse = [
        {
          img:'./ModulHouseBuildBlock.jpeg',
          name: 'Бытовки строительные',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./meModulHouse.jpeg',
          name: 'Бытовки металлические',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./mudulHouseTualet.jpeg',
          name: 'Бытовки с душем и туалетом',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./ModulHouseBurger.jpeg',
          name: 'Бытовки из сэндвич панелей',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
      ]
      let ModullChosBlock = [
        {
          img:'./laundryHouse.jpg',
          name: 'Хозблок модульный',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./ModuHouseTualet2.jpeg',
          name: 'Модульный туалет',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
      ]
      let train = [
        {
          img:'./trinBuild.jpeg',
          name: 'Строительный Вагончик',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
        {
          img:'./trainHouse.jpeg',
          name: 'Модульный вагончик',
          price: 1635000,
          discount: 1735000,
          star: '★★★★☆',
        },
      ]
    const cubinsLine = useRef()
    const blockLine = useRef()
    const KPPLine = useRef()
    const HouseLine = useRef()
    const [cubinsBTN , setCubinsBTN] = useState({
        next: true,
        prev: false,
        cubinsSlider: 0,
        cubinsLine: cubinsLine,
        mass: ModulCabins,
    })
    const [houseBTN , sethouseBTN] = useState({
      next: true,
      prev: false,
      cubinsSlider: 0,
      cubinsLine: HouseLine,
      mass: ModulHouse,
  })
    const [cubinsBTNBlock , setcubinsBTNBlock] = useState({
      next: false,
      prev: false,
      cubinsSlider: 0,
      cubinsLine: blockLine,
      mass: ModulBlock
    })
    const [cubinsBTNKPP , setcubinsBTNKPP] = useState({
      next: false,
      prev: false,
      cubinsSlider: 0,
      cubinsLine: KPPLine,
      mass: ModulKPP
    })
    const [cubinsBTNModulHouse , setcubinsBTNModulHouse] = useState({
      next: false,
      prev: false,
      cubinsSlider: 0,
      cubinsLine: KPPLine,
      mass: ModulHouse
    })

    
    function renderMass(mass) {
      return mass.map((item) =><div itemscope itemtype="http://schema.org/ImageObject" className="card">
            <div className="fd-row">
                <p className="star">{item?.star}</p>
                <Link to='' className="card__arrow">→</Link>
            </div>
            <div  className="radius">
                <Link className='LinkImg' to="/buildModulCubinsHostelPage" onClick={()=> store.dispatch(BagSlice.actions.addMainCard(`Производство ${item?.name}`))}>{item?.img != 'false' ?  
                <LazyLoad className="card__img" threshold={ 0.20 }>
                    <img itemprop="contentUrl" src={item?.img} alt={item?.name}/>
                </LazyLoad> :
                    <img  className="img__emptyPhoto" src='/emptyIcon.svg' alt={item?.name}/> 
                }</Link> 
            </div>
            <p className="rent">Производство</p>
            <Link to="/buildModulCubinsHostelPage" onClick={()=> store.dispatch(BagSlice.actions.addMainCard(`Производство ${item?.name}`))} itemprop="name" className="info">{item?.name}</Link>         
            <div className="card__sale">
                <div className="fd-col">
                    {item?.discount > 0 && <p className="discount">{item?.have == 'true' && item?.discount}</p>} 
                    <p className="card__price">От {item?.price}₽</p> 
                </div>
                <Link to="/buildModulCubinsHostelPage" onClick={()=> store.dispatch(BagSlice.actions.addMainCard(`Производство ${item?.name}`))} className='card__bag'>Посмотерть</Link>
            </div> 
        </div>)
    }
    function sliderNext(btn) {
     
        if (document.documentElement.clientWidth > 851) {
          console.log(btn.mass.length);
          if (btn.mass.length > 8) {
         
            if ( btn?.cubinsSlider == 0 ) {
              btn == cubinsBTN && setCubinsBTN({...cubinsBTN, next:  true ,prev: true, cubinsSlider: btn?.cubinsSlider+100})
                
              btn.cubinsLine.current.style.left = -(btn?.cubinsSlider+100) + '%' 
            }
            if ( btn.cubinsSlider == 100 && btn.cubinsSlider < 200) {
              btn == cubinsBTN &&  setCubinsBTN({...cubinsBTN, next:  false ,prev: true, cubinsSlider: btn?.cubinsSlider+100})
              btn.cubinsLine.current.style.left = -(btn?.cubinsSlider+100) + '%' 
            }
          }
        } 
  }
  function sliderPrev(btn) {
      if (document.documentElement.clientWidth > 851) {
        if (btn.mass.length > 8) {
          if ( btn.cubinsSlider == 100) {
            btn == cubinsBTN &&  setCubinsBTN({...cubinsBTN, next:  true ,prev: false, cubinsSlider: btn.cubinsSlider-100})
            btn.cubinsLine.current.style.left = -(btn.cubinsSlider-100) + '%' 
          }
          if (btn.cubinsSlider == 200) {
            btn == cubinsBTN &&  setCubinsBTN({...cubinsBTN, next:  true ,prev: true, cubinsSlider: btn.cubinsSlider-100})
            btn.cubinsLine.current.style.left = -(btn.cubinsSlider-100) + '%' 
          }
        }   
      }
  }
    return (
      <main>
        <div className="katalog">
            <div className="katalog-row">
                <h2>Модульные здания</h2>
                {document.documentElement.clientWidth > 730 && <>
                     <button className={`prev ${!cubinsBTN.prev ? "" : "prev__active" }`} onClick={()=>sliderPrev(cubinsBTN)}>←</button>
                     <button className={`next ${!cubinsBTN.next ? "" : "next__active" }`} onClick={()=>sliderNext(cubinsBTN)}>→</button>
                </>}
             
            </div>
          <div className="slider">
              <div className="katalog-line" ref={cubinsLine}>
              {renderMass(ModulCabins)}
              </div>
          </div>
        </div>
        <div className="katalog">
            <div className="katalog-row">
                <h2>Блок контейнеры</h2>
                {document.documentElement.clientWidth > 730 && <>
                <button className={`prev ${!cubinsBTNBlock.prev ? "" : "prev__active" }`} onClick={()=>sliderPrev(cubinsBTNBlock)}>←</button>
                <button className={`next ${!cubinsBTNBlock.next ? "" : "next__active" }`} onClick={()=>sliderNext(cubinsBTNBlock)}>→</button>
                </>}
            </div>
          <div className="slider">
              <div className="block-line" ref={blockLine}>
              {renderMass(ModulBlock)}
              </div>
          </div>
        </div>
        <div className="katalog">
            <div className="katalog-row">
                <h2>Модульные КПП</h2>
                {document.documentElement.clientWidth > 730 && <>
                <button className={`prev ${!cubinsBTNKPP.prev ? "" : "prev__active" }`} onClick={()=>sliderPrev(cubinsBTNKPP)}>←</button>
                <button className={`next ${!cubinsBTNKPP.next ? "" : "next__active" }`} onClick={()=>sliderNext(cubinsBTNKPP)}>→</button>
                </>}
            </div>
          <div className="slider">
              <div className="block-line" ref={KPPLine}>
              {renderMass(ModulKPP)}
              </div>
          </div>
        </div>
        <div className="katalog">
            <div className="katalog-row">
                <h2>Бытовки</h2>
                {document.documentElement.clientWidth > 730 && <>
                <button className={`prev ${!cubinsBTNModulHouse.prev ? "" : "prev__active" }`} onClick={()=>sliderPrev(houseBTN)}>←</button>
                <button className={`next ${!cubinsBTNModulHouse.next ? "" : "next__active" }`} onClick={()=>sliderNext(houseBTN)}>→</button>
                </>}
            </div>
          <div className="slider">
              <div className="block-line" ref={HouseLine}>
              {renderMass(ModulHouse)}
              </div>
          </div>
        </div>
        <div className="katalog">
            <div className="katalog-row">
                <h2>Хозблоки</h2>
                {document.documentElement.clientWidth > 730 && <>
                <button className={`prev ${!cubinsBTNModulHouse.prev ? "" : "prev__active" }`}>←</button>
                <button className={`next ${!cubinsBTNModulHouse.next ? "" : "next__active" }`}>→</button>
                </>}
            </div>
          <div className="slider">
              <div className="block-line" ref={HouseLine}>
              {renderMass(ModullChosBlock)}
              </div>
          </div>
        </div>
        <div className="katalog">
            <div className="katalog-row">
                <h2>Вагончики</h2>
                {document.documentElement.clientWidth > 730 && <>
                <button className={`prev ${!cubinsBTNModulHouse.prev ? "" : "prev__active" }`}>←</button>
                <button className={`next ${!cubinsBTNModulHouse.next ? "" : "next__active" }`}>→</button>
                </>}
            </div>
          <div className="slider">
              <div className="block-line" ref={HouseLine}>
              {renderMass(train)}
              </div>
          </div>
        </div>
      </main>
    )
}
