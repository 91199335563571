import React, { useState,useEffect,useRef } from 'react'
import "./css/header.scss"
import { store } from '../shared/store/slices/store';
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import  Search  from '../shared/componets/Search';
import { authSlice } from '../shared/store/slices/auth';
import {BagSlice} from "../shared/store/slices/bag"

export default function Header() {
    const navigate = useNavigate()
    const BagStore = useSelector((state) => state.BagSlice);
    const [scroll, setScroll] = useState(false)
    const auth = useSelector((state)=>state.authSlice)
    const location = useLocation()
    const [showSidebar, setShowSideBar] = useState(false)
    const [searchToggle, setSearchToggle] = useState(false)
    const [katalogToggle, setKatalogToggle] = useState(false)
    const [sidebar__pToggle, setSidebar__pToggle] = useState({
        arenda: false,
        build: true
    })
  
    const cards = useSelector((state)=>state.cardsSlice)
    function onSearch() {
        window.scrollTo(0, 0)
        setKatalogToggle(false)
        setSearchToggle(!searchToggle)
    }
    function handleClick(name){
        store.dispatch(BagSlice.actions.addMainCard(name))
        setKatalogToggle(false)
    }
    function handleClickModal(name){
        store.dispatch(BagSlice.actions.addMainCard(name))
        window.location.reload();
    }
    function handleCLoseKatalog(){
        setKatalogToggle(false)
        setShowSideBar(!showSidebar)
    }
    function handleOpenKatalog(){
        setKatalogToggle(true)
        setShowSideBar(false)
    }
  return (
<header className='header'>
    {searchToggle &&
        <div  className="search__modal">
            <p  onClick={()=>onSearch()} className='search__arrow'>←</p>
            {<Search setSearchToggle={setSearchToggle}/>}
        </div>
    } 
    <div className="headerTop">
        <div className="headerTop__logotip" itemScope itemType="http://schema.org/ImageObject">
            <img alt="логотип"  src="/iconPWA/logo96x96.svg" itemProp="contentUrl" />
        </div>
        <Link to="/" className="headerTop__logo">MSB<span>container</span></Link>
        <div className="headerTop__search">
            {<Search setSearchToggle={setSearchToggle}/>}
        </div>
        <div className="headerTop__contacts">
           <p>+7 (910) 829-76-76</p>
           <p>info@msb-container.ru</p> 
        </div>
        <div className="headerTop__log">
            {auth.userData == null ?  <>
                <Link to="/login" className="login">Войти</Link>
                <Link to="/register" className="register">Зарегистрироваться</Link>
            </> : 
            <Link to={`/user/${auth.userData.idUser}`} className="loginUser"> <img src="/log.svg" alt="пользователь"/><p>{auth.userData.name}</p></Link> }
         
        </div>
    </div>
    {document.documentElement.clientWidth > 630 ?<>
        <div className={`services ${scroll && "services__fixed"} ${katalogToggle && "services__fixed"}`} onScroll={window.onscroll = ()=> window.pageYOffset > 103 ? setScroll(true) :  setScroll(false)} >
        <div  className='services__katalog' onClick={()=>setKatalogToggle(!katalogToggle)}>Каталог {katalogToggle ? <img src= '/katalogClose.svg' alt='закрыть'/> : <div className="menu"><span></span></div>} </div>
        {document.documentElement.clientWidth > 1180 && <>
            {location.pathname.split("/")[1] == 'buildModulCubinsHostelPage' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство модульное общежитие"))}>Модульные здания</Link>}
            {location.pathname.split("/")[1] == 'buildModulCubinsHostelPage' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство блок контейнеры сантехнические"))}>Блок контейнеры</Link>}
            {location.pathname.split("/")[1] == 'buildModulCubinsHostelPage' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство Модульные посты охраны"))}>Модульные КПП</Link>}
            {location.pathname.split("/")[1] == 'buildingCubins' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство модульное общежитие"))}>Модульные здания</Link>}
            {location.pathname.split("/")[1] == 'buildingCubins' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство блок контейнеры сантехнические"))}>Блок контейнеры</Link>}
            {location.pathname.split("/")[1] == 'buildingCubins' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство Модульные посты охраны"))}>Модульные КПП</Link>}
            {location.pathname.split("/")[1] == 'MiddleBlock' && <Link to='/MiddleBlock' onClick={()=>handleClickModal("Производство модульное общежитие")}>Модульные здания</Link>}
            {location.pathname.split("/")[1] == 'MiddleBlock' && <Link to='/MiddleBlock' onClick={()=>handleClickModal("Производство блок контейнеры сантехнические")}>Блок контейнеры</Link>}
            {location.pathname.split("/")[1] == 'MiddleBlock' && <Link to='/MiddleBlock' onClick={()=>handleClickModal("Производство Модульные посты охраны")}>Модульные КПП</Link>}
        </>}
        <Link to="/aboutUs" onClick={()=>setKatalogToggle(false)}>О нас</Link>
        <Link to="/contact" onClick={()=>setKatalogToggle(false)}>Контакты</Link>
        <Link className='bag__headerMargin' to="/bag" onClick={()=>setKatalogToggle(false)}><img src="/bagHeader.svg" alt="корзина"/><div className="services__sum"><p>{BagStore.items?.length}</p></div>Корзина</Link>
        <img  className="services__media" onClick={()=>onSearch()} src="/search.svg" alt="поиск"/>
        <div className="sidebar__open" onClick={()=>handleCLoseKatalog()}>
            <span></span>
        </div>
       {showSidebar && 
       <div className="sidebar">
            <div className="sidebar__menu">
                <div className="sidebar__row">
                    <div className='sidebar__katalogBTN' onClick={()=>handleOpenKatalog()}>Каталог <div className="iconMenu"><span></span></div></div>
                    <p className="sidebar__close" onClick={()=>setShowSideBar(!showSidebar)}>X</p>
                </div>
                <Link to="/aboutUs" onClick={()=>setKatalogToggle(false)}>О нас</Link>
                <Link to="/contact" onClick={()=>setKatalogToggle(false)}>Контакты</Link>
                {location.pathname.split("/")[1] == 'buildModulCubinsHostelPage' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство модульное общежитие"))}>Модульные здания</Link>}
                {location.pathname.split("/")[1] == 'buildModulCubinsHostelPage' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство блок контейнеры сантехнические"))}>Блок контейнеры</Link>}
                {location.pathname.split("/")[1] == 'buildModulCubinsHostelPage' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство Модульные посты охраны"))}>Модульные КПП</Link>}
                {location.pathname.split("/")[1] == 'buildingCubins' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство модульное общежитие"))}>Модульные здания</Link>}
                {location.pathname.split("/")[1] == 'buildingCubins' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство блок контейнеры сантехнические"))}>Блок контейнеры</Link>}
                {location.pathname.split("/")[1] == 'buildingCubins' && <Link to='/MiddleBlock' onClick={()=>store.dispatch(BagSlice.actions.addMainCard("Производство Модульные посты охраны"))}>Модульные КПП</Link>}
                {location.pathname.split("/")[1] == 'MiddleBlock' && <Link to='/MiddleBlock' onClick={()=>handleClickModal("Производство модульное общежитие")}>Модульные здания</Link>}
                {location.pathname.split("/")[1] == 'MiddleBlock' && <Link to='/MiddleBlock' onClick={()=>handleClickModal("Производство блок контейнеры сантехнические")}>Блок контейнеры</Link>}
                {location.pathname.split("/")[1] == 'MiddleBlock' && <Link to='/MiddleBlock' onClick={()=>handleClickModal("Производство Модульные посты охраны")}>Модульные КПП</Link>}
                <Link className='bag__headerMargin' to="/bag" onClick={()=>setKatalogToggle(false)}><img src="/bagHeader.svg" alt="корзина"/><div className="services__sum"><p>{BagStore.items?.length}</p></div>Корзина</Link>
            </div>
        </div>
        } 
    </div>
    {katalogToggle && 
       <div className="katalog__modal">
            <div className="modal__sideBar">
                <Link to="/buildingCubins" onClick={()=>handleClick("модульное,контейнеры,КПП,бытовки")} className={`sidebar__p ${sidebar__pToggle.build && 'active'}`}  onMouseEnter={()=>setSidebar__pToggle({arenda: false, build:true})}> <p>Производство</p></Link> 
                <Link to="/katalog" onClick={()=>setKatalogToggle(false)} className={`sidebar__p ${sidebar__pToggle.arenda && 'active'}`} onMouseEnter={()=>setSidebar__pToggle({arenda: true, build:false})}> <p>Аренда бытовок</p></Link> 
            </div>
            {document.documentElement.clientWidth > 800 &&      <div className="modal__aside">
                {sidebar__pToggle.arenda && <div className="aside__block">
                    <h4>Аренда бытовок</h4>
                    <Link to="/card/2" onClick={()=>handleClick('Аренда бытовки для проживания')}>Бытовки для проживания</Link>
                    <Link to="/card/1" onClick={()=>handleClick('Аренда бытовки раздевалки')}>Бытовки раздевалки</Link>
                    <Link to="/card/5" onClick={()=>handleClick('Аренда бытовки прорабские')}>Бытовки прорабские</Link>
                    <Link to="/card/4" onClick={()=>handleClick('Аренда бытовки под склад')}>Бытовки под склад</Link>
                    <Link to="/card/3" onClick={()=>handleClick('Аренда бытовки с душем')}>Бытовки с душем</Link>
                    <Link className='lastA' to="/katalog" onClick={()=>setKatalogToggle(false)}>Все товары...</Link>
                </div>
                }
                {sidebar__pToggle.build &&  <div className="aside__block">
                    <div className="block__col">
                        <div className="block__row">
                            <h4>Модульные здание</h4>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство модульное общежитие")}>Модульное общежитие</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство модульная столовая")}>Модульная столовая</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство модульный храм")}>Модульный храм</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство штаб строительства")}>Штаб строительства</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство модульные раздевалки")}>Модульные раздевалки</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство модульные ФАПы")}>Модульные ФАПы</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство модульные отели")}>Модульные отели</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство модульный офис")}>Модульный офис</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство вахтовый поселок")}>Вахтовый поселок</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство строительный городок")}>Строительный городок</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство административно-бытовой комплекс")}>Административно-бытовой комплекс</Link>
                        </div>
                        <div className="block__row">
                            <h4>Блок контейнеры</h4>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство блок контейнеры сантехнические")}>Блок контейнеры сантехнические</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство блок контейнеры строительные")}>Блок контейнеры строительные</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство блок контейнеры сэндвич-панели")}>Блок контейнеры из сэндвич панелей</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство блок контейнер склад")}>Блок контейнер под склад</Link>
                        </div>
                        <div className="block__row">
                            <h4>Модульные кпп</h4>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Модульные посты охраны")}>Модульные посты охраны</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Проходная")}>Проходная</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Будка охраны")}>Будка охраны</Link>
                        </div>
                    </div>
                    <div className="block__col">
                    <div className="block__row">
                            <h4>Бытовки</h4>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Бытовки строительные")}>Бытовки строительные</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Бытовки металлические")}>Бытовки металлические</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Бытовки с душем и туалетом")}>Бытовки с душем и туалетом</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Бытовки из сэндвич панелей")}>Бытовки из сэндвич панелей</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Бытовки утепленные")}>Бытовки утепленные</Link>
                        </div>
                        <div className="block__row">
                            <h4>Хозблоки</h4>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Хозблок модульный")}>Хозблок модульный</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Модульный туалет")}>Модульный туалет</Link>
                        </div>
                        <div className="block__row">
                            <h4>Вагончики</h4>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Строительный вагончик")}>Строительный вагончик</Link>
                            <Link to="/buildModulCubinsHostelPage" onClick={()=>handleClick("Производство Модульный вагончик")}>Модульный вагончик</Link>
                        </div>
                    </div>
                    
                </div>
                }
            </div>}
        
       </div> 
    }
    </>  : 
    <div className={location.pathname.split("/")[1] != 'register' ? location.pathname.split("/")[1] != 'login' && `mobile__header ${scroll && "mobile__header__fixed"}` : false} onScroll={window.onscroll = ()=> window.pageYOffset > 103 ? setScroll(true) :  setScroll(false)}>
        {location.pathname.split("/")[1] != 'register' ? location.pathname.split("/")[1] != 'login' ? <>
            <img alt="логотип" className='mobile__logo' src="/iconPWA/logo48x48.svg" itemProp="contentUrl" />
            {location.pathname.split("/")[1] == '' &&  <p className='mobile__header__text'>Главная</p>}
            {location.pathname.split("/")[1] == 'katalog' &&  <p className='mobile__header__text'>Каталог</p>}
            {location.pathname.split("/")[1] == 'gallary' &&  <p className='mobile__header__text'>Товары</p>}
            {location.pathname.split("/")[1] == 'bag' &&  <p className='mobile__header__text'>Корзина</p>}
            {location.pathname.split("/")[1] == 'register' &&  <p className='mobile__header__text'>Регистрация</p>}
            {location.pathname.split("/")[1] == 'login' &&  <p className='mobile__header__text'>Вход</p>}
            {location.pathname.split("/")[1] == 'user' &&  <p className='mobile__header__text'>{auth?.userData?.name}</p>}
            {location.pathname.split("/")[1] == 'arrange' &&  <p className='mobile__header__text'>Оформление заказа</p>}
            {location.pathname.split("/")[1] == 'buildingCubins' &&  <p className='mobile__header__text'>Производство</p>}
            {location.pathname.split("/")[1] == 'buildModulCubinsHostelPage' &&  <p className='mobile__header__text'>{JSON.parse(localStorage.getItem("nameMainCard"))}</p>}
            {location.pathname.split("/")[1] == 'MiddleBlock' &&  <p className='mobile__header__text'>{JSON.parse(localStorage.getItem("nameMainCard")).split(' ')[1]} { JSON.parse(localStorage.getItem("nameMainCard")).split(' ')[2]}</p>}
            {location.pathname.split("/")[1] == 'card' &&  <p className='mobile__header__text'>{cards?.items.find(item=>item.idCard == location.pathname.split("/")[2])?.name}</p>}
            <img className="mobile__search"onClick={()=>onSearch()} src="/search.svg" alt="поиск"/>
        </> : <p onClick={()=>navigate('/')} className='existRegiser'>{'<'}</p> : <p onClick={()=>navigate('/')} className='existRegiser'>{'<'}</p>}
    </div>
    }
    
    </header>
  )
}
