import React, {useEffect, useState} from 'react'
import {cardsSlice, getCards, getCardsImg, addCards} from "../shared/store/slices/cards"
import { useSelector } from 'react-redux'
import { store } from '../shared/store/slices/store';
import { Link, useLocation} from 'react-router-dom';
import CardsCard from '../shared/componets/CardsCard';
import "./scss/activeCard.scss"
import LoadingPageAdmin from './LoadingPageAdmin'

export default function ActiveCard() {
  const location = useLocation()
  const cards = useSelector((state)=>state.cardsSlice)
  const [showSideBar, setShowSideBar] = useState(false)
  function reverse(array){
    let newMass1 = []
    array.map(item =>{
      if (item?.role.split(' ')[0] == 'Аренда' || item?.role.split(' ')[0] == 'общая'){
        newMass1 = [...newMass1, item]
      }
    })
    return newMass1.map((item,idx) => newMass1[newMass1.length-1-idx])
  }
  
  const [orderFilter, setOrderFilter] = useState(cards?.items)
  let newMassReverse = reverse(orderFilter)
  async function MiddleWareCreateCard() {
    await store.dispatch(addCards({}))
  }
  const [nameChecked ,setNameChecked] = useState({
    "Аренда бытовки раздевалки": false,
    "Аренда бытовки для проживания": false,
    "Аренда бытовки c душем": false,
    "Аренда бытовки прорабские": false,
    "Аренда бытовки под склад": false,
    "Аренда мебель": false,
  })
  let newMass = []
  for (let key in nameChecked){
    newMass = [...newMass, key]
  }

  function allCheckboxFalse(object) {
    let flag = true;
    Object.values(object).forEach(x=>{
        if (x) {flag = false}})
    return flag
  }

  function filterData() {
    const filterMass = cards?.items.filter(item => {
        let trueItem = true;
        if (!allCheckboxFalse(nameChecked)) {
            trueItem = trueItem && (nameChecked[item.role] ? true : false)
            return trueItem
          } else{
          return trueItem
        }
    })
    console.log(filterMass);
    setOrderFilter(filterMass)
  }
  function ShowFillter() {
    return (
    <div className="katalog__filter2">
        <div className="katalog-name">
          <div className="katalog-row">
            <h2>Наименование</h2>
            <p className="filter__close" onClick={()=>setShowSideBar(!showSideBar)} >x</p>
          </div>
         <>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Аренда бытовки раздевалки": !nameChecked["Аренда бытовки раздевалки"]})} checked={nameChecked["Аренда бытовки раздевалки"]}/>{"Аренда бытовки раздевалки"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Аренда бытовки для проживания": !nameChecked["Аренда бытовки для проживания"]})} checked={nameChecked["Аренда бытовки для проживания"]}/>{"Аренда бытовки для проживания"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Аренда бытовки c душем": !nameChecked["Аренда бытовки c душем"]})} checked={nameChecked["Аренда бытовки c душем"]}/>{"Аренда бытовки c душем"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Аренда бытовки прорабские": !nameChecked["Аренда бытовки прорабские"]})} checked={nameChecked["Аренда бытовки прорабские"]}/>{"Аренда бытовки прорабские"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Аренда бытовки под склад": !nameChecked["Аренда бытовки под склад"]})} checked={nameChecked["Аренда бытовки под склад"]}/>{"Аренда бытовки под склад"}</label>
         <label> <input type="checkbox" onClick={()=>setNameChecked({...nameChecked,  "Аренда мебель": !nameChecked["Аренда мебель"]})} checked={nameChecked["Аренда мебель"]}/>{"Аренда мебель"}</label>
         </> 
        </div>
    </div>
    )
 }

  useEffect(()=>{
    store.dispatch(getCards({}))
    store.dispatch(getCardsImg({}))
  }, [])
  useEffect(()=>{
    filterData()
  }, [cards?.items, nameChecked])
  useEffect(() => {
    window.scrollTo(0, 0)
}, [location])
  return (
    <div className='activeCard'>
      <div className='headerOrder'>
        <button className='btnForCreate' onClick={()=>MiddleWareCreateCard()} >Создать карточку</button> 
        <div className="filter__open" onClick={()=>setShowSideBar(!showSideBar)}><img src="/filter.webp" alt="фильтры"/><p>фильтры</p></div>
      </div>
      <h1>Карточки аренды</h1>
      <div className="cards">
        {cards.isLoading ? <LoadingPageAdmin number={3}/> : cards?.items.length != 0 ?  newMassReverse.map((item, index)=><CardsCard key={index} item={item}/>) :  <div className="order__clear">
            <h2>Карточек пока нет</h2>
            <p><Link>создайте карточку</Link> чтобы она появилась здесь</p>
        </div>}
      </div>
      {showSideBar && ShowFillter()}
      {showSideBar && <div className="background__black"></div>} 
    </div>
  )
}
